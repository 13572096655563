import 'bootstrap/dist/js/bootstrap.js'
import "@fortawesome/fontawesome-free/js/all"
import 'jquery-mask-plugin/dist/jquery.mask.js'
import "parsleyjs";
import _ from 'lodash'

class Common {
  constructor() {
    var CI = this;
    this.formValidation = {};
    this.isEmail = false;
    this.isPhone = false;
    this.ip_Address = "";
    this.details = {};
    this.apiDown = false;
    this.setCurrentTab();
    this.userStorage = false;
    this.adoptedUrl = "";
    this.device = null;
    this.deviceBrowser = null;
    this.deviveSearchEngine = null;
    this.debiceBrand = null;
    this.deviceName = null;
    CI.save_cookie = true;
    $("form").removeClass('last-step')
    // this.deviceDetection()

    $("#phone").mask("00000000000");

    $.getJSON("https://ipapi.co/json/", function (data) {
      if (data != null && data.ip != undefined && typeof data.ip == "string") {
        CI.ip_Address = data.ip;
      }
    });

    // $(window).on("load", function () {
    //   if (localStorage.getItem("user_data") != null) {
    //     var adopted_url = JSON.parse(
    //       localStorage.getItem("user_data")
    //     ).adopted_url.split(/[: /]+/);
    //     adopted_url = adopted_url.length > 1 ? adopted_url[1] : adopted_url[0];
    //     if (adopted_url == "") {
    //       adopted_url = adopted_url[0];
    //     }
    //     var clearStorgaeData = JSON.parse(
    //       localStorage.getItem("user_data")
    //     ).clearStorage;
    //     if (
    //       (adopted_url == "switch-mobile.co.uk" ||
    //         adopted_url == "switchenergy.today") &&
    //       clearStorgaeData == undefined
    //     ) {
    //       localStorage.removeItem("user_data");
    //     }
    //   }
    // });

    window.Parsley.on("field:error", function () {
      $(".nextStep").removeClass("in-progress");
      $(".tab").removeClass("in-progress");
    });

    window.FontAwesomeConfig = {
      searchPseudoElements: true,
    };
  }

  deviceDetection() {
    this.device = FRUBIL.device.class_code; //Desktop
    this.deviceBrowser = FRUBIL.client.class_code; // Browser
    this.deviveSearchEngine = FRUBIL.client.name_code; // Chrome
    this.deviceBrand = FRUBIL.device.brand_code; // Samsung
    this.deviceName = FRUBIL.device.marketname; // Galaxy A5
  }

  setCurrentTab() {
    var CI = this;
    var tabs = $(".tab");
    $("#local-storage-next-button").addClass("d-none");
    CI.currentTab = 0;
    if (tabs.length > 0) {
      tabs[CI.currentTab].style.display = "block";
    }
  }

  popupPartners() {
    $(".close-b").click(function () {
      $(".modal2").hide();
    });

    $(".partner-text").click(function () {
      $(".modal2").show();
    });
  }

  getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
    } else {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
  }

  checkCookieExist() {
    var CI = this;
    if (this.getCookie("klaro") != null && this.getCookie("klaro") != "") {
      CI.save_cookie = CI.getCookie("klaro");
      CI.save_cookie = JSON.parse(decodeURIComponent(CI.save_cookie))[
        "Local Storage"
      ];
    }
  }

  updateUserInStorage() {
    var CI = this;
    var previousData = this.getItemFromStorage("user_data");
    var currentData = this.getData();
    var userData = _.mergeWith(currentData, previousData, (current, previous) =>
      current == "" || current == "unknown" ? previous : current
    );
    CI.setItemToStorage("user_data", userData);
  }

  getItemFromStorage(name) {
    return JSON.parse(localStorage.getItem(name));
  }

  setItemToStorage(name, data) {
    if (data.adopted_url == "" || data.adopted_url == null) {
      data.adopted_url = data.optinurl;
    } else {
      this.adoptedUrl = data.adopted_url;
    }
    return localStorage.setItem(name, JSON.stringify(data));
  }

  toggleIcon() {
    $(".navbar-toggler").on("click", function () {
      $(".navbar-collapse").toggleClass("d-block");
      $(".body-head").toggleClass("pt-md-16");
      $(".heading-top").toggleClass("pt-11");
    });
  }

  getFormDetails(form) {
    var data = $(form)[0].dataset.details;
    this.details = JSON.parse(data);
  }

  showClock() {
    var clockElement = $(".clock");
    var clock = new FlipClock(clockElement, 6600, {
      countdown: true,
    });
  }

  showToolTip() {
    $('[data-toggle="tooltip"]').tooltip();

    $(document).on("click", ".content", function (event) {
      $('[data-toggle="tooltip"]').tooltip("toggle");
    });
  }

  getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");
      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? true
          : this.formattingParams(sParameterName[1]);
      }
    }
  }

  formattingParams(param) {
    let name = decodeURIComponent(param);
    name.includes("+") ? (name = name.split("+").join(" ")) : name;
    return name;
  }

  getFormattedCurrentDate() {
    var date = new Date();
    var day = this.addZero(date.getDate());
    var monthIndex = this.addZero(date.getMonth() + 1);
    var year = date.getFullYear();
    var min = this.addZero(date.getMinutes());
    var hr = this.addZero(date.getHours());
    var ss = this.addZero(date.getSeconds());

    return (
      day + "/" + monthIndex + "/" + year + " " + hr + ":" + min + ":" + ss
    );
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  validate(form) {
    this.formValidation = $("#dealform").parsley({
      trigger: "focusout",
      errorClass: "error",
      successClass: "valid check-name",
      errorsWrapper: '<div class="parsley-error-list"></div>',
      errorTemplate: '<label class="error"></label>',
      errorsContainer(field) {
        if (field.$element.hasClass("postcode")) {
          return $(".postcode-error");
        }
        if (field.$element.hasClass("error-on-button")) {
          return $(field.element.closest(".tab").querySelector(".error-box"));
        }
        if (field.$element.hasClass("fuel_type_input")) {
          return $(field.element.closest(".tab").querySelector(".error-fuel"));
        }
        if (field.$element.hasClass("bedrooms_input")) {
          return $(field.element.closest(".tab").querySelector(".error-bedroom"));
        }
        return field.$element.parent();
      },
    });

    this.validatePhone();
    this.validateEmail();
    this.validatePostcode();
    this.validateApiPostcode();
  }

  formatPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.replace(/\D/g, '');
    if (phoneNumber.startsWith('4478')) {
      phoneNumber = '+44' + phoneNumber.substring(2);
    } else if (phoneNumber.startsWith('078') || phoneNumber.startsWith('78')) {
      phoneNumber = '+44' + phoneNumber.substring(phoneNumber.startsWith('078') ? 1 : 0);
    } else if (!phoneNumber.startsWith('+44')) {
      phoneNumber = '+44' + phoneNumber;
    }
    return phoneNumber;
  }

  validatePhone() {
    var CI = this;
    window.Parsley.addValidator("validphone", {
      validateString: function (value) {
        var phoneNum = $(".phone").val();
        var deferred = $.Deferred();
        $.ajax({
          url: "/validate_phone", // Backend endpoint
          type: "POST",
          data: {
            phone_number: phoneNum,
            country: "GB"
          }
        })
          .done(function (response) {
            if (response.valid) {
              CI.isPhone = true;
              deferred.resolve();
            } else {
              deferred.reject("Please Enter Valid UK Phone Number");
            }
          })
          .fail(function (response) {
            if (response.valid) {
              CI.isPhone = true;
              deferred.resolve();
            } else {
              deferred.reject("Please Enter Valid UK Phone Number");
            }
          });
  
        return deferred.promise();
      },
      messages: {
        en: "Please Enter Valid UK Phone Number",
      },
    });
  }
  

  // validateEmail() {
  //   var CI = this;
  //   window.Parsley.addValidator("validemail", {
  //     validateString: function(value) {
  //       var email = $(".email").val()
  //       var deferred = $.Deferred(); 
  //       $.ajax({
  //         url: "https://conversionhero.io/api/v1/validation/email",
  //         type: "POST",
  //         headers: {
  //           "Authorization": "Bearer your_api_key_here", 
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           "Accept": "application/json"
  //         },
  //         data: {
  //             "email": email
  //         }
  //       }).done(function(json) {
  //         if (json.status == "VALID") {
  //           CI.isEmail = true;
  //           deferred.resolve();
  //         } else if (json.status == "INVALID" || json.status == "ERROR") {
  //           deferred.reject("Please Enter Valid Email Address"); 
  //         } else {
  //           CI.isEmail = true;
  //           deferred.resolve();
  //         }
  //       }).fail(function() {
  //         CI.isEmail = true;
  //         deferred.resolve();
  //       });
  //       return deferred.promise();
  //     },
  //     messages: {
  //       en: "Please Enter Valid Email Address",
  //     },
  //   });
  // }

  validateEmail() {
    var CI = this;
    window.Parsley.addValidator("validemail", {
      validateString: function (value) {
        var xhr = $.ajax(
          "https://go.webformsubmit.com/dukeleads/restapi/v1.2/validate/email?key=50f64816a3eda24ab9ecf6c265cae858&value=" +
            $(".email").val()
        );
        return xhr.then(function (json) {
          if (json.status == "VALID") {
            CI.isEmail = true;
            return true;
          } else if (json.status == "INVALID" || json.status == "ERROR") {
            return $.Deferred().reject("Please Enter Valid Email Address");
          } else {
            CI.isEmail = true;
            return true;
          }
        });
      },
      messages: {
        en: "Please Enter Valid Email Address",
      },
    });
  }

  validatePostcode() {
    window.Parsley.addValidator("validPostcode", {
      validateString: function (value) {
        return /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/i.test(
          value
        );
      },
      messages: {
        en: "Please Enter Valid UK Postcode",
      },
    });
  }

  validateApiPostcode() {
    var CI = this;
    window.Parsley.addValidator("validapipostcode", {
      validateString: function (value) {
        return $.ajax({
          url: `https://api.getAddress.io/find/${$(
            ".postcode"
          ).val()}?api-key=NjGHtzEyk0eZ1VfXCKpWIw25787&expand=true`,
          success: function (json) {
            $(".property-div").show();
            if (json.addresses.length > 0) {
              var result = json.addresses;
              var adresses = [];
              adresses.push(`
                <option
                disabled=""
                selected=""
                >
                Select Your Property
                </option>
              `);
              for (var i = 0; i < result.length; i++) {
                adresses.push(`
                    <option
                    data-street="${result[i].line_1 || result[i].thoroughfare}"
                    data-city="${result[i].town_or_city}"
                    data-province="${
                      result[i].county || result[i].province || result[i].town_or_city
                    }"
                    data-street2="${result[i].line_2}"
                    data-street3="${result[i].line_3}"
                    data-street4="${result[i].line_4}"
                    data-district="${result[i].district}"
                    data-building="${
                      result[i].building_number ||
                      result[i].sub_building_number ||
                      result[i].building_name ||
                      result[i].sub_building_name
                    }"
                    >
                    ${result[i].formatted_address
                      .join(" ")
                      .replace(/\s+/g, " ")}
                    </option>
                  `);
              }
              $("#property").html(adresses);
              $(".address-div").remove();
              return true;
            } else {
              $(".tab").removeClass("in-progress");
              return $.Deferred().reject("Please Enter Valid Postcode");
            }
          },
          error: function (request) {
            console.log(request.statusText);
            request.abort();
            if (request.statusText == "timeout") {
              $(".property-div").remove();
            }
          },
          timeout: 5000,
        });
      },
      messages: {
        en: "Please Enter Valid Postcode",
      },
    });
  }

  showTab(n = 0) {
    var tabs = $(".tab");
    if (!tabs[n]) return;
    tabs[n].style.display = "block";
    this.fixStepIndicator(n);
    $(".nextStep").removeClass("in-progress");
  }

  fillform() {
    var zipcode = this.getUrlParameter("postcode") || ""; // 'Friday+September+13th'
    zipcode = zipcode.replace(/\+/g, "%20"); // 'Friday%20September%2013th'
    zipcode = decodeURIComponent(zipcode); // 'Friday September 13th'
    $(".first_name").val(this.getUrlParameter("firstname") || "");
    $(".last_name").val(this.getUrlParameter("lastname") || "");
    $(".postcode").val(zipcode || "");
    $(".email").val(this.getUrlParameter("email") || "");
    $(".phone").val(
      this.getUrlParameter("phone1") || this.getUrlParameter("mobile") || ""
    );
    $(".street1").val(this.getUrlParameter("street1") || "");
    $(".energy-provider").val(this.getUrlParameter("currentprovider")).change();
  }

  showCircle() {
    $(".nextStep").addClass("in-progress");
    $(".tab").addClass("in-progress");
  }

  fixStepIndicator(num) {
    var progress = document.getElementById("progressBar");
    if (num >= 0) {
      progress.style.width = Math.ceil(num * 11.11) + "%";
      $(".progress-percent").text(Math.ceil(num * 11.11) + "%" + " Complete");
      $(".progress-steps").text("Step " + (num + 1) + "/10");
      if (num == 0) {
        $(".progress-percent").text("");
      }
    }
  }

  backStep() {
    var CI = this;
    var n = -1;
    if (CI.currentTab > 0) {
      var tabs = $(".tab");
      tabs[CI.currentTab].style.display = "none";
      CI.currentTab = CI.currentTab + n;
      console.log("back Step" + CI.currentTab)
      CI.showTab(CI.currentTab);
    }
  }
  checkName(form) {
    var status = [];
    var firstNameTab = $(form).find(".error-message-firstname")[0];
    var lastNameTab = $(form).find(".error-message-lastname")[0];
    const hasVowelsRegex = /[aeiou]/g;
    const hasconsonantRegex = /[bcdfghjklmnpqrstvwxys]/g;
    var firstname = $(".first_name").val().toLowerCase();
    var lastname = $(".last_name").val().toLowerCase();
    var firstNameHasBoth =
      !!firstname.match(hasVowelsRegex) && !!firstname.match(hasconsonantRegex);
    var lastNameHasBoth =
      !!lastname.match(hasVowelsRegex) && !!lastname.match(hasconsonantRegex);
    var today = new Date();
    var time =
      today.getHours() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getSeconds() +
      ":" +
      today.getMilliseconds();
    console.log(time);
    $.ajax({
      type: "GET",
      url: `/checkname?fn=${firstname}&ln=${lastname}&fullName=${
        firstname + " " + lastname
      }`,
      async: false,
      success: function (data) {
        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds() +
          ":" +
          today.getMilliseconds();
        console.log(time);
        console.log("****************************************");
        status = data["errors"];
      },
    });
    if (
      firstname.length < 2 ||
      firstname.length > 15 ||
      lastname.length < 2 ||
      !firstNameHasBoth ||
      !lastNameHasBoth ||
      status.length != 0 ||
      lastname.length > 15
    ) {
      var validIcon = $(form).find(".check-name");
      firstNameTab.classList.add("d-none");
      lastNameTab.classList.add("d-none");

      status.forEach(function (error, index, arr) {
        if (error == "first name disallowed") {
          validIcon[0].classList.remove("valid");
          validIcon[0].classList.add("error");
          firstNameTab.classList.remove("d-none");
          firstNameTab.innerHTML = "Entered name not allowed";
        }
        if (error == "last name disallowed") {
          validIcon[1].classList.remove("valid");
          validIcon[1].classList.add("error");
          lastNameTab.classList.remove("d-none");
          lastNameTab.innerHTML = "Entered name not allowed";
        }
        if (error == "full name disallowed") {
          for (let item of validIcon) {
            item.classList.remove("valid");
            item.classList.add("error");
            firstNameTab.classList.remove("d-none");
            firstNameTab.innerHTML = "Entered name not allowed";
            lastNameTab.classList.remove("d-none");
            lastNameTab.innerHTML = "Entered name not allowed";
          }
        }
      });
      return true;
    } else {
      firstNameTab.classList.add("d-none");
      lastNameTab.classList.add("d-none");
      return false;
    }
  }
  customValidation(form, currentTab) {
    switch (currentTab) {
      case 0:
        if ($(form).find(".postcode").val().length < 3) {
          return false;
        } else {
          return true;
        }
        break;
      case 8:
        if (this.checkName(form)) {
          return false;
        } else {
          return true;
        }
        break;
      case 9:
        if (
          $(form).find(".email").val() != "" &&
          $(form).find(".phone").val() < 11
        ) {
          return false;
        } else {
          return true;
        }
        break;
      default:
        return true;
    }
  }

  nextStep() {
    var n = 1;
    var CI = this;
    console.log(CI.currentTab)
    $("#dealform")
      .parsley()
      .whenValidate({
        group: "block-" + CI.currentTab,
      }).done(() => {
      var tabs = $(".tab");
        tabs[CI.currentTab].style.display = "none";
        CI.currentTab = CI.currentTab + n;
        console.log("next step" + CI.currentTab)
        if (CI.currentTab >= tabs.length) {
          if (CI.isPhone == true) {
            CI.showCircle();
            CI.postData();
          } else {
            $("#dealform").parsley().validate();
          }
          return true;
        }
        CI.showTab(CI.currentTab);
    });
  }

  getData() {
    return {
      postcode: $(".postcode").val() || this.getUrlParameter("postcode") || "",
      firstname:
        $(".first_name").val() || this.getUrlParameter("firstname") || "",
      lastname: $(".last_name").val() || this.getUrlParameter("lastname") || "",
      email: $(".email").val() || this.getUrlParameter("email") || "",
      phone1: $(".phone").val() || this.getUrlParameter("phone1") || "",
      street1:
        $(".street1").val() ||
        $(".address").val() ||
        this.getUrlParameter("street1") ||
        "",
      street2: $(".street2").val() || this.getUrlParameter("street2") || "",
      building: $(".building").val() || this.getUrlParameter("building") || "",
      title:
        $("input[name='title']:checked").val() ||
        this.getUrlParameter("title") ||
        "Mr",
      lead_id: this.getUrlParameter("lead_id") || "unknown",
      sid: this.getUrlParameter("sid") || 1,
      source:
        this.getUrlParameter("source") || "Google-Mobile",
      keyword: this.getUrlParameter("keyword") || "",
      ssid: this.getUrlParameter("ssid") || this.details.ssid || "unknown",
      paymentmethod:
        $("input[name='currently-payment-type']:checked").val() ||
        this.getUrlParameter("paymentmethod") ||
        "unknown",
      currentprovider:
        $("#energy-provider option:selected").val() ||
        $("input[name='energy-provider']:checked").val() ||
        this.getUrlParameter("currentprovider") ||
        "unknown",
        residentialStatus:
        $("#residential-status option:selected").val() ||
        $("input[name='residential-status']:checked").val() ||
        this.getUrlParameter("residentialStatus") ||
        "unknown",
      prize: this.getUrlParameter("prize") || "2",
      trafficid: this.getUrlParameter("trafficid") || "switchenergy",
      towncity: $(".towncity").val() || this.getUrlParameter("towncity") || "",
      c1:
        this.getUrlParameter("transid") ||
        this.getUrlParameter("c1") ||
        "unknown",
      optindate: this.getFormattedCurrentDate(),
      optinurl: this.details.optinUrl || "http://switchenergy.today/",
      ipaddress: this.details.ipaddress,
      apiDown: this.apiDown,
      timestamp: new Date(),
      url_with_params: window.location.href,
      uu_id: this.details.uu_id || "",
      user_agent: window.navigator.userAgent,
      lead_from_local_storage: this.userStorage,
      campaign_name: this.details.camp_id,
      adopted_url: this.adoptedUrl,
      adclid: this.getUrlParameter('adclid') || "",
      clickid: this.getUrlParameter('gclid') || "",
      gclid: this.getUrlParameter("gclid") || $(".gclid").val() || "",
      campaign: this.getUrlParameter("campaign") || $(".campaign").val() || "",
      keyword: this.getUrlParameter("keyword") || $(".keyword").val() || "",
      ad_set: this.getUrlParameter("ad_set") || $(".ad_set").val() || "",
      mkwid: this.getUrlParameter("mkwid") || $(".mkwid").val() || "",
      monthly_bill: $("#monthly_bill").val(),
      fuelType: $("input[name='fuel_type']:checked").val(),
      energyUsage: $("input[name='avg_consumption']:checked").val(),
      county: $(".county").val() || this.getUrlParameter('county') || "",
      matchType: this.getUrlParameter("matchType") || $(".matchType").val() || "",
      adGroupId: this.getUrlParameter("adGroupId") || $(".adGroupId").val() || "",
      aff_id: this.getUrlParameter("aff_id") || 3,
      // device:this.device || '',
      // device_browser:this.deviceBrowser || '',
      // devive_search_engine:this.deviveSearchEngine || '',
      // device_brand:this.deviceBrand || '',
      // device_name:this.deviceName || '',
      clearStorage: false,
    };
  }

  firePixel() {
    dataLayer.push({ event: "transaction" });
  }

  USTransaction() {
    dataLayer.push({ event: "USTransaction" });
  }

  postData() {
    var CI = this;
    this.firePixel();
    CI.checkCookieExist();
    // $("form").addClass("in-progress");
    $('#main-index-page').addClass('d-none');
    $('#loader').removeClass('d-none');
    $('#loader-content').html($(".postcode").val());
    $(".tab").removeClass("in-progress");
    var data = this.getData();
    console.log("Postdata: " + new Date());
    this.submitLead(data, this.details.camp_id);
  }

  billBuddyUrl() {
    window.location =
      "https://savings.switchenergy.today/tax-rebate" +
      this.paramsForRedirectURL();
    //last whatapp url = https://api.whatsapp.com/send?phone=447427792139&text=Please%20send%20me%20energy%20deals
    //last url  = "https://tracking.selectnews.uk/aff_c?offer_id=2105&aff_id=1641&source=exit"
    //old url = https://www.awin1.com/cread.php?awinmid=3041&awinaffid=652417&clickref=enery-exit&ued=https%3A%2F%2Fwww.bt.com%2F
  }
  successUrl(response) {
    if (response.code == 1) {
      var deliveriesCount = response.records[0].deliveries.length;
      var deliveries = response.records[0].deliveries;
      var i = 0;
      var deliveryCount = 0;
      for (i = deliveriesCount - 1; i >= 0; i--) {
        if (deliveries[i].status == "Sent") {
          deliveryCount = 1;
          if (deliveries[i].name == "Scottish Power Premium") {
            window.location =
              "https://news.energynews.live/wfh-exit" +
              this.paramsForRedirectURL();
            break;
          } else if (deliveries[i].name == "SP customers to Shell") {
            window.location =
              "https://news.energynews.live/wfh-exit" +
              this.paramsForRedirectURL();
            break;
          } else {
            window.location =
              "https://news.energynews.live/wfh-exit" +
              this.paramsForRedirectURL();
            break;
          }
        }
      }
      if (deliveryCount == 0) {
        this.billBuddyUrl();
      }
    } else {
      this.billBuddyUrl();
    }
  }

  submitLeadToStore(formData) {
    console.log(formData);
    var CI = this;
    $.ajax({
      type: "POST",
      url: "https://dukestore.herokuapp.com/api/v1/lead",
      dataType: "json",
      data: { lead: formData },
      success: function (data) {
        console.log(data);
      },
      error: function (request) {
        console.log(request.statusText);
      },
    });
  }

  // submitLead(data, campid) {
  //   this.submitLeadToStore(data);
  //   var CI = this;
  //   $.ajax({
  //     type: "POST",
  //     url:
  //       "https://go.webformsubmit.com/dukeleads/waitsubmit?key=eecf9b6b61edd9e66ca0f7735dfa033a&campid=" +
  //       campid,
  //     data: data,
  //     success: function (response) {
  //       console.log(response);
  //       CI.successUrl(response);
  //     },
  //     dataType: "json",
  //   });
  // }

  additionalParams() {
    return "&s5=" + this.getPostcode();
  }
  // return "?email=" + this.getEmail() + "&firstName=" + this.getFirstname() + "&gas=" + getMonthlybill()

  paramsForRedirectURL() {
    return (
      "?" +
      jQuery.param({
        firstName: this.getFirstname(),
        lastName: this.getLastname(),
        mobile: this.getPhone(),
        email: this.getEmail(),
        line1: this.getStreet(),
        line2: this.getStreet2(),
        line3: this.getStreet3(),
        line4: this.getStreet4(),
        gclid: this.getUrlParameter("gclid") || $(".gclid").val() || "",
        source: this.getUrlParameter("source") || $(".source").val() || "",
        sid: this.getUrlParameter("sid") || $(".sid").val() || "",
        ssid: this.getUrlParameter("ssid") || $(".ssid").val() || "",
        mkwid: this.getUrlParameter("mkwid") || $(".mkwid").val() || "",
        ad_set: this.getUrlParameter("ad_set") || $(".ad_set").val() || "",
        city: this.getTownCity(),
        postcode: this.getPostcode(),
        province: this.getDistrict(),
        title: this.getTitle().toLowerCase(),
        affId: "686e5c5c-54c4-4c68-adb5-a1c596f21123",
        matchType: this.getUrlParameter("matchType") || $(".matchType").val() || "",
        adGroupId: this.getUrlParameter("adGroupId") || $(".adGroupId").val() || "",
      })
    );
    // return "?postcode=" + this.getPostcode() + "&firstname=" + this.getFirstname() + "&lastname=" + this.getLastname() + "&email=" + this.getEmail() + "&phone=" + this.getPhone();
  }
  paramsforMMD() {
    return (
      "?postcode=" +
      this.getPostcode() +
      "&firstname=" +
      this.getFirstname() +
      "&lastname=" +
      this.getLastname() +
      "&email=" +
      this.getEmail() +
      "&phone1=" +
      this.getPhone()
    );
  }
  getPostcode() {
    return this.getUrlParameter("postcode") || $(".postcode").val() || "";
  }
  getMonthlybill() {
    return (
      this.getUrlParameter("monthlybill") || $(".monthly_bill").val() || ""
    );
  }
  getTitle() {
    return (
      $("input[name='title']:checked").val() ||
      this.getUrlParameter("title") ||
      "Mr"
    );
  }
  getFirstname() {
    return this.getUrlParameter("firstname") || $(".first_name").val() || "";
  }
  getLastname() {
    return this.getUrlParameter("lastname") || $(".last_name").val() || "";
  }
  getEmail() {
    return this.getUrlParameter("email") || $(".email").val() || "";
  }
  getPhone() {
    return this.getUrlParameter("phone1") || $(".phone").val() || "";
  }
  getSource() {
    return (
      this.getUrlParameter("source") || this.details.source || "Google-Mobile"
    );
  }
  getStreet() {
    return (
      this.getUrlParameter("street1") ||
      $(".street1").val() ||
      $(".address").val() ||
      ""
    );
  }
  getStreet2() {
    return $(".street2").val() || this.getUrlParameter("street2") || "";
  }
  getStreet3() {
    return $(".street3").val() || this.getUrlParameter("street3") || "";
  }
  getStreet4() {
    return $(".street4").val() || this.getUrlParameter("street4") || "";
  }
  getDistrict() {
    return $(".district").val() || this.getUrlParameter("district") || "";
  }
  getTownCity() {
    return this.getUrlParameter("towncity") || $(".towncity").val() || "";
  }
  getCurrentProvider() {
    return (
      this.getUrlParameter("currentprovider") ||
      $("#energy-provider option:selected").val() ||
      $("input[name='energy-provider']:checked").val() ||
      "unknown"
    );
  }
  getStreetNo() {
    if ($(".street1").val() == "" || this.checkEquality()) {
      $(".street1").val($(".towncity").val());
    }    
  }
  checkEquality() {
    if ($(".street1").val() == this.getUrlParameter("street1")) {
      return true;
    }
    return false;
  }
}

export default Common;
