import Common from "./common.js"

class Form extends Common{
  constructor() {
    super();
    var CI = this;
    this.token = ''
    this.residentialValue = ''
    this.unique_id = ''
    this.validate("#dealform")
    this.getFormDetails('#dealform')
    this.showToolTip()
    this.fillform()
    this.popupPartners()
    this.toggleIcon()
    this.showTab(this.currentTab);
    var chk1 = $("input[type='checkbox'][name='agree']");
    var chk2 = $("input[type='checkbox'][name='partners-tp']");

    chk1.on('change', function(){
     chk2.prop('checked',this.checked);
    });
    var focusbtn = document.getElementById('focusButton')
    if (focusbtn) {
      document.getElementById('focusButton').addEventListener('click', function() {
        var input = document.getElementById('postcode');
        input.scrollIntoView({ behavior: 'smooth', block: 'center' });
        input.focus();
      });
    }

    document.addEventListener("DOMContentLoaded", function () {
      const questionElements = document.querySelectorAll("[data-action='click->form#toggleAnswer']");
      questionElements.forEach(function (questionElement) {
        questionElement.addEventListener("click", function () {
          const answerId = this.getAttribute("data-id");
          const answerElement = document.getElementById("answer" + answerId);
          const crossIconElements = document.querySelectorAll("[data-target='form.crossIcon'][data-id='" + answerId + "']");
          answerElement.classList.toggle("answer-visible");
          crossIconElements.forEach(function (crossIconElement) {
            crossIconElement.classList.toggle("tw-opacity-0");
          });
        });
      });
    });
    $('.buttonAddressList').click(function(event) {
      event.preventDefault();
      $('.propertyDiv').show();
    })

    $('.address-link').click(function(event) {
      event.preventDefault();
      
      $('.address-link').each(function() {
        $(this).removeClass('selected');
      });
      $(this).toggleClass('selected'); // Toggle highlight class
      const selectedAddress = $(this);
      $('.selectedAddress').text(selectedAddress[0].innerText)
      $('.selectedAddressDiv').show();
      $('.towncity').val(selectedAddress.data('city'));
      $('.street1').val(selectedAddress.data('street'));
      $('.fullAddress').val(selectedAddress.data('fulladdress'));
      $('.flatNumber').val(selectedAddress.data('flat'));
      $('.building').val(selectedAddress.data('building'));
      $('.county').val(selectedAddress.data("province"))
      $(".uprn").val(selectedAddress.data("uprn"));
      $(".mpan").val(selectedAddress.data("mpan"));
      $('.propertyDiv').hide(); // Hide after selection
      CI.nextStep(1);
    });
    this.accessToken();

    var next = _.debounce(this.nextStep.bind(this), 100, false);
    var back = _.debounce(this.backStep.bind(this), 100, false);
    // $( ".nextStep" ).click(next);
    $( ".nextStep" ).click(function(event) {
      $(".nextStep").addClass("in-progress")
      CI.nextStep()
    });
    $( ".updateLead" ).click(function(event) {
      $(".updateLead").addClass("in-progress")
      CI.updateLead()
    });

    const dropdownButton = document.getElementById('residentialStatus');
    const dropdownMenu = document.getElementById('residentialStatusMenu');
    var dropdownItems = ''
    if (dropdownMenu) {
      dropdownItems = dropdownMenu.querySelectorAll('li');
    }
    const errorElement = document.getElementById('residentialStatusError');
    if (dropdownButton) {
      dropdownButton.addEventListener('click', (event) => {
        event.preventDefault();
        dropdownMenu.classList.toggle('tw-hidden');
      });    
      // document.addEventListener('click', (event) => {
      //   if (!dropdownButton.contains(event.target) && !dropdownMenu.contains(event.target)) {
      //     dropdownMenu.classList.add('tw-hidden');
      //   }
      // });
      dropdownItems.forEach((item) => {
        item.addEventListener('click', (event) => {
          const selectedValue = event.target.getAttribute('data-residentialValue'); // Get the value from data-value
          const selectedText = event.target.textContent; // Get the text content of the selected option
          // Update the dropdown button text
          dropdownButton.textContent = selectedText;
          CI.residentialValue = selectedValue
          console.log('Selected Value:', selectedValue); // Log the selected value
          // Hide the dropdown after selection
          dropdownMenu.classList.add('tw-hidden');
          errorElement.classList.add('tw-hidden');
        });
      });
    } 
    $( ".backStep" ).click(back);

    $( ".firstStep" ).on('click',function (){
      const pstcodeBtn = document.querySelector(".postcodeBtn");
      const firstStepBtn = document.querySelector(".firstStep");
      if (pstcodeBtn) {
        firstStepBtn.classList.add("tw-hidden")
        pstcodeBtn.classList.remove("tw-hidden")
      }
    });


    $( ".energyprovider" ).change(function() {
      if ($("input[name='energyprovider']:checked").val() !== '' && $("input[name='energyprovider']:checked").val() !== undefined && $("input[name='fuel_type']:checked").val() !== "" && $("input[name='fuel_type']:checked").val() !== undefined) {
        CI.nextStep(1);
      }
    });

    $( ".currently-payment-type" ).change(function() {
      if ($("input[name='currently-payment-type']:checked").val() !== "" && $("input[name='currently-payment-type']:checked").val() !== undefined && CI.residentialValue !== '') {
        CI.nextStep(1);
      }
    });

    $( ".fuel_type" ).change(function() {
      if ($("input[name='energyprovider']:checked").val() !== '' && $("input[name='energyprovider']:checked").val() !== undefined  && $("input[name='fuel_type']:checked").val() !== "" && $("input[name='fuel_type']:checked").val() !== undefined ) {
        CI.nextStep(1);
      }
    });

    $( ".house_type" ).change(function() {
      if ($("input[name='house_type']:checked").val() !== "" && $("input[name='house_type']:checked").val() !== undefined && $("input[name='bedroom_count']:checked").val() !== "" && $("input[name='bedroom_count']:checked").val() !== undefined) {
        CI.nextStep(1);
      }
    });
    $( ".bedroom_count" ).change(function() {
      if ($("input[name='house_type']:checked").val() !== "" && $("input[name='house_type']:checked").val() !== undefined && $("input[name='bedroom_count']:checked").val() !== "" && $("input[name='bedroom_count']:checked").val() !== undefined) {
        CI.nextStep(1);
      }
    });
    
  }

  nextStep() {
    var n = 1;
    var CI = this;
    if (CI.currentTab == 0) {
      let randomNumber = CI.getRandomNumber(10, 25);
      console.log(randomNumber);
      $(".randomNumber").text(randomNumber);
      $(".pstcodeText").text($('.postcode').val());
    }
    console.log(CI.currentTab)
    $("#dealform")
      .parsley()
      .whenValidate({
        group: "block-" + CI.currentTab,
      }).done(() => {
      var tabs = $(".tab");
      if (CI.currentTab == 2) {
        if (CI.residentialValue !== '') {
          tabs[CI.currentTab].style.display = "none";
          CI.currentTab = CI.currentTab + n;
          console.log("next step" + CI.currentTab)
        } else {
          const errorElement = document.getElementById('residentialStatusError');
          errorElement.classList.remove('tw-hidden')
          $(".nextStep").removeClass("in-progress")
          return true
        }
      }else{
        tabs[CI.currentTab].style.display = "none";
        CI.currentTab = CI.currentTab + n;
        console.log("next step" + CI.currentTab)
      }
      if (CI.currentTab == 5) {
        CI.showOverlayLoader();
        CI.postData();
      }
      CI.showTab(CI.currentTab);
      var form = document.getElementById('dealform');
      if (form) {
        window.scrollTo({ top: form.offsetTop, behavior: 'smooth' });
      }
    });
  }

  updateLead() {
    var n = 1;
    var CI = this;
    if (CI.currentTab == 0) {
      let randomNumber = CI.getRandomNumber(10, 25);
      console.log(randomNumber);
      $(".randomNumber").text(randomNumber);
      $(".pstcodeText").text($('.postcode').val());
    }
    console.log(CI.currentTab)
    $("#dealform")
      .parsley()
      .whenValidate({
        group: "block-" + CI.currentTab,
      }).done(() => {
      var tabs = $(".tab");
      tabs[CI.currentTab].style.display = "none";
      CI.currentTab = CI.currentTab + n;
      console.log("next step" + CI.currentTab)
      if (CI.currentTab >= tabs.length) {
        if (CI.isPhone == true) {
          CI.showOverlayLoader();
          if (CI.getUrlParameter("customer_id") !== "" && CI.getUrlParameter("customer_id") !== undefined) {
            CI.checkLead();
          }
        } else {
          $("#dealform").parsley().validate();
        }
        return true;
      }
      CI.showTab(CI.currentTab);
      var form = document.getElementById('dealform');
      if (form) {
        window.scrollTo({ top: form.offsetTop, behavior: 'smooth' });
      }
    });
  }

  showOverlayLoader() {
    const loader = document.getElementById('overlay-loader');
    loader.classList.remove('tw-hidden');
  }
  hideOverlayLoader(){
    const loader = document.getElementById('overlay-loader');
    loader.classList.add('tw-hidden');
  }

  showTab(n = 0) {
    var tabs = $(".tab");
    if (!tabs[n]) return;
    tabs[n].style.display = "block";
    $(".nextStep").removeClass("in-progress");
  }
  backStep() {
    var CI = this;
    var n = -1;
    if (CI.currentTab > 0) {
      $('.propertyDiv').hide();
      var tabs = $(".tab");
      tabs[CI.currentTab].style.display = "none";
      CI.currentTab = CI.currentTab + n;
      console.log("back Step" + CI.currentTab)
      CI.showTab(CI.currentTab);
    }
  }

  

  sendSlackNotification(error, spxSessionId){
    $.ajax({
      type: "GET",
      url: "/send_slack_notification",
      data: {message: error, hitMatrixId: spxSessionId},
      success: function(data) {
        console.log(data)
      }
    });
  }

  accessToken() {
    let CI = this;  
    $.ajax({
      type: "GET",
      url: "/get_access_token",
      success: function(data) {
        CI.token = data.token;
      },
      error: function (errorMessage) {
        console.log(errorMessage)
      }
    });
  }

  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  validateApiPostcode() {
    var CI = this; 
    window.Parsley.addValidator("validapipostcode", {
      validateString: function (value) {
        const pstcodeBtn = document.querySelector(".postcodeBtn");
        const firstStepBtn = document.querySelector(".firstStep");
        if (pstcodeBtn) {
          firstStepBtn.classList.add("in-progress", "tw-opacity-20")
        }
        return $.ajax({
          type: "GET",
          url: `https://api.rebelenergy.com/api/v2/addresses/${$(
            ".postcode"
          ).val()}`,
          headers: {
            'Authorization': `Bearer ${CI.token}`
          },
          success: function (json) {
            $(".property-div").show();
            if (json.length > 0) {
              var result = json;
              var addresses = [];
              for (var i = 0; i < result.length; i++) {
                addresses.push(`
                  <a href="#"
                    class="address-link"
                    data-street="${result[i]?.street}"
                    data-city="${result[i]?.posttown}"
                    data-fullAddress="${result[i]?.fullAddress}"
                    data-building="${result[i]?.number}"
                    data-uprn="${result[i]?.uprn}"
                    data-mpan="${result[i]?.mpan}"
                    data-flat="${result[i]?.flat}">
                      ${result[i].fullAddress}
                    </a>
                  `);
              }
              $('#property').html(addresses.join(''));
              $('.propertyDiv').show();
              $('.address-link').click(function(event) {
                event.preventDefault();
                const pstcodeBtn = document.querySelector(".postcodeBtn");
                const firstStepBtn = document.querySelector(".firstStep");
                if (pstcodeBtn) {
                  firstStepBtn.classList.add("tw-hidden")
                  pstcodeBtn.classList.remove("tw-hidden")
                }
                $('.address-link').each(function() {
                  $(this).removeClass('selected');
                });
                $(this).toggleClass('selected'); // Toggle highlight class
                const selectedAddress = $(this);
                $('.selectedAddress').text(selectedAddress[0].innerText)
                $('.selectedAddressDiv').show();
                $('.towncity').val(selectedAddress.data('city'));
                $('.street1').val(selectedAddress.data('street'));
                $('.fullAddress').val(selectedAddress.data('fulladdress'));
                $('.flatNumber').val(selectedAddress.data('flat'));
                $('.building').val(selectedAddress.data('building'));
                $('.county').val(selectedAddress.data("province"))
                $(".uprn").val(selectedAddress.data("uprn"));
                $(".mpan").val(selectedAddress.data("mpan"));
                $('.propertyDiv').hide();                
              });
              $(".address-div").remove();
              return true;
            } else {
              return $.Deferred().reject("Please Enter Valid Postcode");
            }
          },
          error: function (request) {
            console.log(request.statusText);
            request.abort();
            if (request.statusText == "timeout") {
              var spxSessionId = localStorage.getItem('spxSessionId');
              var hitMatrixURL = `https://cp-inst47-client.phonexa.uk/p8/hitmetrix/recordings/play?sessionId=${spxSessionId}`
              CI.sendSlackNotification(request, "Address LookUp switchenergy Home Hit-Matrix-Id: " + hitMatrixURL)
              $(".property-div").remove();
              $(".address-div").show();
              $("#postcode").removeAttr('data-parsley-required');
              $("#postcode").removeAttr('required');
              $(".postcode").removeClass('error');
              $(".postcode").addClass('valid');
              $(".postcode-error").remove();
              $(".parsley-error-list").remove();
              $(".property-dropdown").show();
              CI.apiDown = true;
            }
          },
          timeout: 10000,
        });
      },
      messages: {
        en: "Please Enter Valid Postcode",
      },
    });
  }

  getData() {
    var spxSessionId = localStorage.getItem('spxSessionId');
    if (spxSessionId) {
      $(".spxSessionId").val(spxSessionId)
    }
    var urlWithParams = window.location.href;
    var bedroom_numbers = document.getElementById('total-bedrooms');
    if (bedroom_numbers) {
      bedroom_numbers = bedroom_numbers.innerText
    }
    if (bedroom_numbers <= 0) {
      bedroom_numbers = $("input[name='bedroom_count']:checked").val()
    }
    const urlPath = window.location.pathname;
    const id = urlPath.split('/').pop();
    return {
      urlWithParams: urlWithParams,
      spxSessionId: spxSessionId || $(".spxSessionId").val() || "",
      postcode: $(".postcode").val() || this.getUrlParameter("postcode") || "",
      firstname:
        $(".first_name").val() || this.getUrlParameter("firstname") || "",
      lastname: $(".last_name").val() || this.getUrlParameter("lastname") || "",
      email: $(".email").val() || this.getUrlParameter("email") || "",
      phoneNumber: $(".phone").val() || this.getUrlParameter("phone1") || "",
      street1: $(".street1").val() || this.getUrlParameter("street1") || "",
      building: $(".building").val() || this.getUrlParameter("building") || "",
      title:
        $("input[name='title']:checked").val() ||
        this.getUrlParameter("title") ||
        "Mr",
      lead_id: this.getUrlParameter("lead_id") || "unknown",
      sid: this.getUrlParameter("sid") || 1,
      source:
        this.getUrlParameter("source") ||
        this.details.source ||
        "Google-Mobile",
      keyword: this.getUrlParameter("keyword") || "",
      ssid: this.getUrlParameter("ssid") || this.details.ssid || "",
      paymentmethod:
        $("input[name='currently-payment-type']:checked").val() ||
        this.getUrlParameter("paymentmethod") ||
        "",
      currentprovider:
        $("#energy-provider option:selected").val() ||
        $("input[name='energyprovider']:checked").val() ||
        this.getUrlParameter("currentprovider") ||
        "",
        residentialStatus:
        this.residentialValue ||
        this.getUrlParameter("residentialStatus") ||
        "",
      prize: this.getUrlParameter("prize") || "",
      trafficid: this.getUrlParameter("trafficid") || "",
      towncity: $(".towncity").val() || this.getUrlParameter("towncity") || "",
      optindate: this.getFormattedCurrentDate(),
      optinurl: this.details.optinUrl || "",
      ipaddress: this.details.ipaddress  || "",
      apiDown: this.apiDown  || "",
      uu_id: this.details.uu_id || "",
      uprn: this.getUrlParameter('uprn') || $(".uprn").val() || "",
      mpan: this.getUrlParameter('mpan') || $(".mpan").val() || "",
      campaign: this.getUrlParameter("campaign") || "",
      clickid: this.getUrlParameter('gclid') || "",
      adclid: this.getUrlParameter('adclid') || "",
      gclid: this.getUrlParameter("gclid") || $(".gclid").val() || "",
      keyword: this.getUrlParameter("keyword") || $(".keyword").val() || "",
      ad_set: this.getUrlParameter("ad_set") || $(".ad_set").val() || "",
      mkwid: this.getUrlParameter("mkwid") || $(".mkwid").val() || "",
      monthly_bill: $("#monthly_bill").val() || "",
      fuelType: this.getUrlParameter("fuelType") || $("input[name='fuel_type']:checked").val() || "",
      buildingType: this.getUrlParameter("buildingType") || $("input[name='house_type']:checked").val()  || "",
      bedrooms : bedroom_numbers || "",
      county: $(".county").val() || this.getUrlParameter('county') || "",
      matchType: this.getUrlParameter("matchType") || $(".matchType").val() || "",
      adGroupId: this.getUrlParameter("adGroupId") || $(".adGroupId").val() || "",
      fullAddress: this.getUrlParameter("fullAddress") || $(".fullAddress").val() || "",
      flatNumber: this.getUrlParameter("flatNumber") || $(".flatNumber").val() || "",
      pubId: this.getUrlParameter("pubId") || $("input[name='pubId']").val() || "",
      ttclid: this.getUrlParameter("ttclid") || "",
      customer_id: this.getUrlParameter("customer_id") || this.unique_id || "",
    };
  }

  getUpdatedData = async function () {
    try {
      const urlPath = window.location.pathname; // Gets the path, e.g., "/rb-quote/UqjjdcWqiBwpRw"
      // const id = urlPath.split('/').pop();
      const customerId = this.getUrlParameter("customer_id") || "" ;
      const url = `/fetch_updated_data?customer_id=${encodeURIComponent(customerId)}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const dbData = await response.json();
      console.log("Fetched data:", dbData); // Debug response structure
      let processedData = {};
      if (Array.isArray(dbData)) {
        processedData = dbData.reduce((acc, item) => {
          acc[item.key] = item.value; // Adjust to match API response structure
          return acc;
        }, {});
      } else if (dbData && typeof dbData === "object") {
        processedData = { ...dbData }; // Directly copy if it's an object
      } else {
        console.error("Unexpected data structure:", dbData);
      }
      return processedData; // Return the processed data
    } catch (error) {
      console.error("Error fetching data:", error);
      return {}; // Return an empty object on error
    }
  };
  
  postData = async function () {
    var CI = this;
    this.firePixel();
    $(".tab").removeClass("in-progress");
    console.log("Postdata: " + new Date());
    try {
      const leadID = await CI.createLead(); // Wait for createLead to complete
      if (leadID) {
        console.log("Lead created successfully with ID:", leadID);
      } else {
        console.log("Failed to create lead.");
      }
      CI.redirectUrl(); // Redirect after lead creation
    } catch (error) {
      console.error("Error during lead creation:", error);
    }
  };
  
  
  postDatafull = async function () {
    var CI = this;
    this.firePixel();
    $(".tab").removeClass("in-progress");
    console.log("Postdata: " + new Date());
    try {
      const leadID = await CI.createLead(); // Wait for createLead to complete
      if (leadID) {
        console.log("Lead created successfully with ID:", leadID);
      } else {
        console.log("Failed to create lead.");
      }
      CI.successUrl()
    } catch (error) {
      console.error("Error during lead creation:", error);
    }
    
  }

  checkLead(){
    var customerId = '';
    var CI = this;
    if (CI.getUrlParameter("customer_id") !== "" && CI.getUrlParameter("customer_id") !== undefined) {
      customerId = CI.getUrlParameter("customer_id") || "";
    }else{
      return
    }
    fetch(`/check_lead?customer_id=${customerId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.exists) {
          CI.updatedDataInDB();
        } else {
          CI.postDatafull();
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
  }

  updatedDataInDB(){
    var CI = this;
    $.ajax({
      type: "PATCH",
      url: "/update_lead",
      data: this.getData(),
      dataType: "json",  // Make sure to expect JSON response
      success: function(response) {
        if (response.unique_id) {
          CI.redirecttoQuote();
        } else {
          console.log("Error: Unique ID not returned.");
        }
      },
      error: function (errorMessage) {
        console.error("Error: ", errorMessage);
      }
    });
  }
  
  createLead = async function () {
    let CI = this;
    try {
      const response = await $.ajax({
        type: "POST",
        url: "/create_lead",
        data: this.getData(),
      });
      if (response.unique_id) {
        CI.unique_id = response.unique_id;
        return response.unique_id; 
      } else {
        console.log("Error: Unique ID not returned.");
        return null;
      }
    } catch (errorMessage) {
      console.log(errorMessage);
      return null;
    }
  };
  

  redirectUrl(){
    var CI = this
    $.ajax({
      type: "POST",
      url: "/submit_lead_redirct",
      data: this.getData(),
      success: function(data) {
        if(data.data.redirect_url != "" &&  data.data.redirect_url != undefined && data.data.redirect_url != null) {
          CI.hideOverlayLoader()
          window.location.href = data.data.redirect_url
        }else if(data.data.rejectUrl != ""  &&  data.data.rejectUrl != undefined && data.data.rejectUrl != null){
          CI.hideOverlayLoader()
          window.location.href = data.data.rejectUrl+'?'+jQuery.param(CI.getData())
          console.log(data)
        }else {
          CI.hideOverlayLoader()
          window.location.href = "/duplicate"
        }
      },
      error: function (textStatus, errorMessage) {
        console.log(textStatus)
        console.log(errorMessage)
      },
      dataType: "json"
    })
  }

  redirecttoQuote = async function () {
    try {
      const updatedData = await this.getUpdatedData();
      const CI = this;
      $.ajax({
        type: "POST",
        url: "/submit_lead_affid",
        data: updatedData,
        success: function (data) {
          if (data.data.redirect_url) {
            CI.hideOverlayLoader()
            window.location.href = data.data.redirect_url;
          } else if (data.data.rejectUrl) {
            CI.hideOverlayLoader()
            window.location.href = `${data.data.rejectUrl}?${jQuery.param(CI.getData())}`;
            console.log(data);
          } else {
            CI.hideOverlayLoader()
            window.location.href = "/duplicate";
          }
        },
        error: function (textStatus, errorMessage) {
          console.error("Error:", textStatus, errorMessage);
        },
        dataType: "json",
      });
    } catch (error) {
      console.error("Error in redirecttoQuote:", error);
    }
  };
  
  
  successUrl(){
    var CI = this
    $.ajax({
      type: "POST",
      url: "/submit_lead_affid",
      data: this.getData(),
      success: function(data) {
        if(data.data.redirect_url != "" &&  data.data.redirect_url != undefined && data.data.redirect_url != null) {
          CI.hideOverlayLoader()
          window.location.href = data.data.redirect_url
        }else if(data.data.rejectUrl != ""  &&  data.data.rejectUrl != undefined && data.data.rejectUrl != null){
          CI.hideOverlayLoader()
          window.location.href = data.data.rejectUrl+'?'+jQuery.param(CI.getData())
          console.log(data)
        }else {
          CI.hideOverlayLoader()
          window.location.href = "/duplicate"
        }
      },
      error: function (textStatus, errorMessage) {
        console.log(textStatus)
        console.log(errorMessage)
      },
      dataType: "json"
    })
  }
}

export default new Form();
